<template>
  <div>
    <div style="text-align: center;padding-left: 50px;padding-right: 50px">
      <p style="font-size: 25px;word-wrap: break-word">
        {{ dataScanf.maintitle }}
      </p>
    </div>
    <div v-for="file in dataScanf.imgs" style="text-align: center">
      <div v-if="file.substring(file.length - 3, file.length) !== 'mp4'">
        <img :src="file" alt="" style="width: 350px"/>
      </div>
      <div v-if="file.substring(file.length - 3, file.length) === 'mp4'">
        <video width="350" controls>
          <source :src="file" type="video/mp4">
          <object :data="file" width="350">
            <embed :src="file" width="350">
          </object>
        </video>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "mobilsel",
  data() {
    return {
      mainid: '',
      dataScanf: []
    }
  },
  created() {
    this.mainid = this.$route.query.qrcodeid;
    this.axios.post('/qrcodemain/getone', (response) => {
      this.dataScanf = response.obj;

    }, {
      mainid: this.mainid
    })
  },
  methods: {
  }
}
</script>

<style scoped>

</style>